<template>

	<div>
		
		<b-card>
			<b-row>					
				<b-col cols="12" class="mb-1">
					<h5>FILTROS</h5>
				</b-col>

				<b-col sm="12" md="4" class="mb-2">
					<label>Tipo(s) de producto</label>
					<v-select 
						multiple
						v-model="filterProductType"
						:options="optionsProductTypes" />
				</b-col>
				
				<b-col sm="12" md="4">
					<label>Marca(s)</label>
					<v-select 
						multiple
						v-model="filterBrand"
						:options="optionsBrands" />
				</b-col>
				<b-col sm="12" md="4">
					<label>Categorías</label>
					<v-select 
						multiple
						v-model="filterCategory"
						:options="optionsCategories" />
				</b-col>
				<b-col sm="12" md="4">
					<label>Etiquetas</label>
					<v-select 
						multiple
						v-model="filterTag"
						:options="optionsTags" />
				</b-col>				
				<b-col sm="6" md="4" class="mt-1"> 
					<b-row>
						<b-col sm="12">							
							<label>Rango de precio</label>
							<vue-slider
								v-model="filterPrice"
								:max="maxPrice"
								:min="0"
							/>
						</b-col>
					</b-row>
				</b-col>
				<b-col sm="6" md="4" class="mt-1"> 	
					<label>Disponibilidad</label>
					<div class="d-flex">
						<b-form-checkbox
							v-model="filterStock"
							value="1"
							class="custom-control-primary"
						>
							EN STOCK
						</b-form-checkbox>
						<b-form-checkbox
							v-model="filterStock"
							value="0"
							class="custom-control-danger ml-3"
						>
							SIN STOCK
						</b-form-checkbox>
     				</div>
				</b-col>
			</b-row>
		</b-card>
		
		<!-- Table Container Card -->
		<b-card
			no-body
			class="mb-0">

			<div class="m-2">

				<!-- Table Top -->
				<b-row>

					<!-- Per Page -->
					<b-col
						cols="12"
						md="4"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>registros</label>
					</b-col>
													   
					<!-- Search -->
					<b-col
						cols="12"
						md="8">
						<div class="d-flex ">
							<b-form-input
								v-model="searchQuery"
								class="d-inline-block mr-1"
								placeholder="Buscar producto ..."
								trim
							/>
							<b-button
								variant="primary"
								:to="{ name: 'product-create' }"								
								>
								<span class="text-nowrap">Agregar Producto</span>
							</b-button>
						</div>
					</b-col>
				</b-row>

			</div>

			<b-table
				ref="refProductListTable"
				class="position-relative"
				:style="totalProducts < 3 && totalProducts > 0 ? 'height: 250px' : 'height: inherit'"
				:items="fetchProducts"
				responsive
				:fields="tableColumns"
				primary-key="id2"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No se encontraron registros coincidentes"
				:sort-desc.sync="isSortDirDesc">
				
				<template #cell(index)="data">
					{{ data.index + 1 }}
				</template>

				<template #cell(name)="data">
					<b-media vertical-align="center">
						<template #aside>
							<b-avatar
								size="32"
								:src="data.item.images[0]"								
								:text="avatarText(data.item.name)"
								variant="light-success"
								:to="{ name: 'product-edit', params: { id: data.item._id } }"
							/>
						</template>
						<b-link
							:to="{ name: 'product-edit', params: { id: data.item._id } }"
							class="font-weight-bold d-block text-nowrap">
							{{ data.item.name}}
						</b-link>
					</b-media>
				</template>
				
				<template #cell(category.name)="data">
					<b-badge 
						:variant="`light-${data.item.category.color}`"
						>
						{{ data.item.category.name }}
					</b-badge>	
				</template>
				
				<template #cell(tag.name)="data">
					{{ pluckData(data.item.tag, 'name') }}
				</template>
				
				<template #cell(price)="data">
					{{ `S/. ${(data.item.price).toFixed(2)}` }}
				</template>
				
				<template #cell(stock)="data">
					<b-badge v-if="data.item.stock <= 0"
						variant="light-danger"
					>
						SIN STOCK
					</b-badge>	
					<b-col v-else>
						{{ data.item.stock }}
					</b-col>
				</template>
				
				<template #cell(actions)="data">
					<b-dropdown
						variant="link"
						no-caret
						:right="$store.state.appConfig.isRTL">

						<template #button-content>
							<feather-icon
								icon="MoreVerticalIcon"
								size="16"
								class="align-middle text-body"
							/>
						</template>
						
						<b-dropdown-item :to="{ name: 'product-edit', params: { id: data.item._id } }">
							<feather-icon icon="EditIcon" />
							<span class="align-middle ml-50">Editar</span>
						</b-dropdown-item>

						<b-dropdown-item @click="deleteProduct(data.item._id)">
							<feather-icon icon="TrashIcon" />
							<span class="align-middle ml-50">Eliminar</span>
						</b-dropdown-item>
					</b-dropdown>
				</template>

			</b-table>
			<div class="mx-2 mb-2">
				<b-row>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start">
						<span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
					</b-col>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end">

						<b-pagination
							v-model="currentPage"
							:total-rows="totalProducts"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item">

							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>

					</b-col>

				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>

	import {BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormDatepicker} from 'bootstrap-vue';
	import { useToast } from 'vue-toastification/composition';
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
	import vSelect from 'vue-select';
	import store from '@/store';
	import VueSweetalert2 from 'vue-sweetalert2';
	import VueSlider from 'vue-slider-component';
	import { onUnmounted, ref, reactive } from '@vue/composition-api';
	import { avatarText } from '@core/utils/filter';
	import useProductsList from './useProductsList';
	import productStoreModule from '../productStoreModule';
	import Vue from 'vue';    
	import 'animate.css';
	import _ from 'underscore';
	import axios from 'axios';
	
	Vue.use(VueSweetalert2);

	export default {
		components: {
			BCard,
			BRow,
			BCol,
			BFormInput,
			BButton,
			BTable,
			BMedia,
			BAvatar,
			BLink,
			BBadge,
			BDropdown,
			BDropdownItem,
			BPagination,
			BFormDatepicker,					
			// 3rd Party
			VueSlider,
			vSelect
		},
		data() {                                   
			return {
				PublicUrl: process.env.VUE_APP_URL
			}
		}, 
		methods: {
			pluckData (data, key){
				return _.pluck(data, key).join(', ');
			}
		},
		setup() {

			// Use toast
			const toast = useToast();

			const USER_APP_STORE_MODULE_NAME = 'app-product';

			// Register module
			if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, productStoreModule);

			// UnRegister on leave
			onUnmounted(() => {
				if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
			});
												
			const optionsCategories = reactive([]);
			
			axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/categories`)
			.then(response => {
				if(response){
					response.data.map( item => {
						optionsCategories.push({
							label : item.name, 
							value : item._id
						});
					});
				}
			});
			
			const optionsBrands = reactive([]);
			
			axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/brands`)
			.then(response => {
				if(response){					
					response.data.map( item => {
						optionsBrands.push({
							label : item.name, 
							value : item._id
						});
					});
				}
			});

			const optionsProductTypes = reactive([]);
			
			axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/product-types`)
			.then(response => {
				if(response){					
					response.data.map( item => {
						optionsProductTypes.push({
							label : item.name, 
							value : item._id
						});
					});
				}
			});
			
			const optionsTags = reactive([]);
			
			axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/tags`)
			.then(response => {
				if(response){
					response.data.map( item => {
						optionsTags.push({
							label : item.name, 
							value : item._id
						});
					});
				}
			});
						
			const minPrice = ref(0);
			const maxPrice = ref(1);			
			
			axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/products`)
			.then(response => {
			    if(response){
					const prices = response.data.all.map( item => {
						return item.price;
					});
					maxPrice.value = Math.max(...prices);
					minPrice.value = Math.min(...prices);
				}
			});
		
			const deleteProduct = (id) => {
		
					Vue.swal({
						title: '¿Estás seguro de eliminar el producto?',
						text: "¡Si no lo está, puede cancelar la acción!",
						icon: 'warning',
						showCancelButton: true,
						confirmButtonText: 'Sí, eliminar!',
						cancelButtonText: 'Cancelar',
						customClass: {
							confirmButton: 'btn btn-primary',
							cancelButton: 'btn btn-outline-danger ml-1'
						},
						showClass: {
							popup: 'animate__animated animate__tada'
						},
						buttonsStyling: false
					}).then(result => {
						if (result.value) {
							store.dispatch('app-product/deleteProduct', { id })
								.then( (response) => {
									refetchData();
									toast({
										component: ToastificationContent,
										props: {
											title: response.data.message,
											icon: 'CheckIcon',
											variant: 'success'
										}
									});
								})
								.catch( () => {
									toast({
										component: ToastificationContent,
										props: {
											title: 'Error al eliminar el producto',
											icon: 'AlertTriangleIcon',
											variant: 'danger'
										}
									});
								});
						}
					});
			};
			
			const {
				fetchProducts,
				tableColumns,
				perPage,
				currentPage,
				totalProducts,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refProductListTable,
				refetchData,
				
				filterProductType,
				filterBrand,
				filterCategory,
				filterTag,
				filterPrice,	
				filterStock,
				resetPrice,
				
			} = useProductsList();
			
			return {
				fetchProducts,
				tableColumns,
				perPage,
				currentPage,
				totalProducts,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refProductListTable,
				refetchData,
				
				// Filter
				avatarText,
				deleteProduct,
				optionsCategories,
				optionsProductTypes,
				optionsBrands,
				optionsTags,

				filterProductType,
				filterBrand,
				filterCategory,
				filterTag,
				maxPrice,
				minPrice,
				filterPrice,
				filterStock,
				resetPrice,
			}
		}
	}

</script>

<style lang="scss" scoped>

	.media {
		align-items: center;
	}

	.per-page-selector {
		width: 90px;
	}    

</style>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-select.scss';
	@import '@core/scss/vue/libs/vue-sweetalert.scss';
	@import '~@core/scss/vue/libs/vue-slider.scss';
</style>
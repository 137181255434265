import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useProductsList() {

    // Use toast
    const toast = useToast();

    const refProductListTable = ref(null);

    // Table Handlers
    let tableColumns = [
        { key: 'name', sortable: true, label: 'Producto' },
        { key: 'brand.name', sortable: true, label: 'Marca' },
        { key: 'category.name', sortable: true, label: 'Categoría' },
        { key: 'tag.name', sortable: false, label: 'Etiqueta(s)' },
        { key: 'product_type.name', sortable: true, label: 'Tipo de Producto' },
        { key: 'price', sortable: true, label: 'Precio' },
        { key: 'stock', sortable: true, label: 'Stock' },
        { key: 'actions', label: 'Opciones' }
    ];

    const perPage = ref(10);
    const totalProducts = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('id');
    const isSortDirDesc = ref(false);
    const filterCategory = ref(null);
    const filterProductType = ref(null);
    const filterBrand = ref(null);
    const filterTag = ref(null);
    const filterPrice = ref([0, 1]);
    const filterStock = ref(null);
    const resetPrice = ref(null);

    const dataMeta = computed(() => {
        const localItemsCount = refProductListTable.value ? refProductListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalProducts.value,
        }
    });

    const refetchData = () => {
        refProductListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, filterCategory, filterProductType, filterBrand, filterTag, filterPrice, filterStock], () => {
        refetchData();
    });

    const fetchProducts = (ctx, callback) => {

        let filterCategoryArray = [];
        if (filterCategory.value) {
            filterCategory.value.map(item => {
                filterCategoryArray.push(item.value);
            });
        }

        let filterProductTypeArray = [];
        if (filterProductType.value) {
            filterProductType.value.map(item => {
                filterProductTypeArray.push(item.value);
            });
        }

        let filterBrandArray = [];
        if (filterBrand.value) {
            filterBrand.value.map(item => {
                filterBrandArray.push(item.value);
            });
        }

        let filterTagArray = [];
        if (filterTag.value) {
            filterTag.value.map(item => {
                filterTagArray.push(item.value);
            });
        }

        store
            .dispatch('app-product/fetchProducts', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                filterCategory: filterCategory.value ? filterCategoryArray.join(',') : null,

                filterProductType: filterProductType.value ? filterProductTypeArray.join(',') : null,
                filterBrand: filterBrand.value ? filterBrandArray.join(',') : null,
                filterTag: filterTag.value ? filterTagArray.join(',') : null,
                filterStock: filterStock.value,
                filterPrice: filterPrice.value[1] > 1 ? filterPrice.value : null
            })
            .then(response => {
                const { products, total } = response.data;
                callback(products);
                totalProducts.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error al obtener la lista de productos',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                });
            })
    };

    return {
        fetchProducts,
        tableColumns,
        perPage,
        currentPage,
        totalProducts,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refProductListTable,

        refetchData,
        // Extra Filters
        filterCategory,
        filterProductType,
        filterBrand,
        filterTag,
        filterPrice,
        filterStock,
        resetPrice,
    }
}